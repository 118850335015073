<template lang="html">
  <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
    <div class="inner">
      <p class="eula">
        <b>Acuerdo de licencia de usuario final</b> <br><br>
        <b>Fecha efectiva: Agosto 17, 2021</b> <br><br>
        <b>Definiciones</b> <br><br>
        Lea este Acuerdo de licencia de usuario final (EULA) detenidamente antes de hacer clic en el botón "Acepto", descargar o usar cualquiera de las aplicaciones de Nexgen English Online Co. ("Nexgen") (Myneo, aplicación de estudio neo, neo estudio en línea, y neo LIVE), Nexgen cursos, Nexgen sitios web o Nexgen servicios ("Solicitud"). Nexgen es el propietario de la Aplicación y es una corporación registrada en California. Este EULA es un acuerdo legal entre un individuo o entidad única (usted, su) y Nexgen y rige el uso que usted haga de la Aplicación, el Software del curso y los Servicios que Nexgen pone a su disposición. Al hacer clic en el botón "Acepto", descargar o usar la Aplicación, usted acepta estar sujeto a los términos y condiciones de este EULA. Si no está de acuerdo con los términos de este EULA, no haga clic en el botón "Acepto" y no descargue ni utilice la Aplicación.

        <br><br><b>Licencia</b><br><br>
        Nexgen le otorga la licencia de la Aplicación, no la vende. Nexgen le otorga una licencia revocable, no exclusiva, intransferible y limitada para descargar, instalar y usar la Aplicación, únicamente parar los propósitos establecidos en los términos de este EULA. La Aplicación está disponible solo para uso individual y no es transferible. Al registrarse, el servicio ejecutará un procedimiento de confirmación que concluirá con una "Confirmación de cuenta" que proclamará que usted es el propietario de su cuenta después de "Confirmar". Si tiene conocimiento de cualquier uso no autorizado de su cuenta, notifique inmediatamente a Nexgen. Nexgen no será responsable de cualquier pérdida o daño que surja de su incumplimiento de esta disposición. Sus derechos para usar la Aplicación se especifican en este EULA, y Nexgen se reserva todos los derechos que no se le otorgan expresamente en este EULA. No puede copiar, reproducir, distribuir o explotar ningún contenido, código, datos, materiales o diseño, incluidos, entre otros, los derechos de autor, derechos de patente, derechos de bases de datos., o cualquier otra propiedad intelectual y derechos de propiedad sobre los mismos. El uso de aplicaciones, cursos o servicios de Nexgen no le otorga la propiedad de ningún contenido, código, datos o materiales de Nexgen. Cualquier distribución, publicación, o la explotación de la Aplicación Nexgen está estrictamente prohibida a menos que haya recibido un permiso previo por escrito de Nexgen. Nada en este EULA constituye una renuncia a los derechos de Nexgen bajo la Ley de derechos de autor internacional de los Estados Unidos o cualquier otra ley federal o estatal, leyes de derechos de autor o cualquier otra ley aplicable de otras naciones.

        <br><br><b>Servicios de terceros</b><br><br>
        La Aplicación puede mostrar, incluir o poner a disposición contenido de terceros (incluidos datos, información, aplicaciones y otros servicios de productos) o proporcionar enlaces a sitios web o servicios de terceros ("Servicios de terceros"). Usted reconoce y acepta que Nexgen no será responsable de ningún Servicio de terceros, incluida su precisión, integridad, puntualidad, validez, cumplimiento de los derechos de autor, legalidad, decencia, calidad o cualquier otro aspecto de los mismos. Nexgen no asume ni tendrá ninguna obligación o responsabilidad ante usted o cualquier otra persona o entidad por los Servicios de terceros. Los Servicios de terceros y los enlaces a los mismos se proporcionan únicamente para su conveniencia y su acceso.; utilícelos enteramente bajo su propio riesgo y sujeto a los términos y condiciones de dichos terceros.

        <br><br><b>Términos de servicio</b><br><br>
        1. Usando la Aplicación, usted estudia para completar con éxito un nivel del Marco Común Europeo de Referencia para las lenguas ("MCER") y puede obtener un certificado de inglés correlacionado con el MCER.<br>
        2. La Aplicación requiere que complete sus puntos de estudio y de Coaching. Los puntos de estudio se obtienen al estudiar con la Aplicación. Los requisitos de los puntos de estudio dependen del nivel de certificación y se pueden encontrar en la aplicación. Nexgen sugiere que lleve a cabo una sesión de coaching en línea con la aplicación cada dos semanas, donde cada sesión le otorgará puntos de coaching. Los requisitos de los Coaching Points se encuentran en la Aplicación y en las Preguntas más Frecuentes de Nexgen ("FAQ ").<br>
        3. Una vez que complete el estudio y los requisitos de Coaching Points, Nexgen le pedirá que realice en la aplicación el Certification Test del nivel. En este punto, puede optar por realizar la el Certification Test o estudiar lecciones de revisión antes de tomar el Certification Test.<br>
        4. Si no pasa el Certification Test, Nexgen le pedirá que estudie lecciones de revisión en la aplicación. El Certification Test del nivel se abrirá nuevamente después de que haya completado las lecciones de revisión requeridas. Nexgen permitirá que la el Certification Test de un nivel se abra un máximo de tres (3) veces<br>
        5. Después de aprobar examen del Certification Test, Nexgen pondrá a disposición tu certificado obtenido una vez que hayas pagado la tarifa del nivel.<br>
        6. Nexgen hará que su certificado esté disponible a través de la Aplicación. Puede optar por imprimir el certificado para presentarlo en su escuela, universidad, o empleador.<br>
        7. Los certificados correlacionados con el MCER de Nexgen son emitidos por la empresa educativa DynEd International, Inc., con sede en San José, California.<br>
        8. Nexgen proporciona un simulador de sesión, una herramienta exclusiva que puede utilizar para simular una sesión de coaching en línea sobre la Aplicación. Ejecutar este paso es obligatorio para determinar si su conexión a Internet es adecuada para conectarse e interactuar en una sesión de coaching en línea. Este paso debe ejecutarse en el entorno donde se llevará a cabo la sesión reservada. En el caso de que la conexión a Internet no ofrezca suficiente ancho de banda o estabilidad, Nexgen sugiere cambiar a una conexión a Internet más rápida o más estable. Nexgenes no es responsable por cualquier perturbación de la conexión al intentar conectarse o durante las sesiones en curso.<br>
        9. Si nuestro coach capacitado no se presenta a una sesión de entrenamiento en línea programada o llega tarde cinco minutos o más, Nexgen le facilitará y pondrá a disposición una sesión sustitutiva de coaching.<br>

        <br><b>Certificado de garantía</b><br><br>
        1. Nexgen también ofrece la garantía de que, en un número específico de meses, aprobará con éxito el Certification Test del nivel adquirido y recibir un certificado correlacionado con el MCER. Vea Nexgen’s FAQ , la preguntas más frecuentes, para tiempos de estudio garantizados.<br>
        2. Para calificar para la garantía, debe ganar un número predeterminado de Puntos de Estudio cada semana y completar un número mínimo de sesiones de Coaching por nivel. Ver Nexgen’s FAQ, preguntas más frecuentes, para los puntos de estudio y los requisitos de las sesiones de coaching.<br>
        3. Si no aprueba el primer Certification Test del nivel en o antes del último día del período garantizado para tu nivel de estudio, Nexgen te permite agendar otra sesión de coaching online para que pueda estar mejor preparado para un segundo intento en el Certification Test.<br>
        4. La garantía de Nexgen es solo para otorgar una sesión de coaching en línea gratuita(s) por fallar por primera vez en el Certification Test de un nivel. Las fallas posteriores del Certification Test, del mismo nivel, no resultan una sesión de entrenamiento en línea adicional(s). Si ha completado todos los requisitos para la garantía y no ha pasado los exámenes en línea, Nexgen se comunicará con usted para recibir una entrevista personal en línea con un experto.<br>
        5. Si su Certification Test se abre después del último día del período garantizado para su nivel de estudio y no pasa el examen en su primer intento, Nexgen no facilitará ninguna sesión adicional de coaching en línea.<br>

        <br><b>Política de privacidad</b><br><br>
        La política de privacidad de Nexgen cubre la recopilación y el uso de información personal que Nexgen puede recopilar cada vez que interactúa con Nexgen, como cuando visita nuestro sitio web, cuando utiliza productos y servicios de Nexgen o cuando se comunica con nuestros representantes de soporte.<br>
        1. <b>¡Por qué Nexgen recopila informacion personal?</b> Nexgen recopila información personal para ayudarle a brindar un nivel superior de servicio y soporte. Además, su información personal le ayuda a mantenerse informado sobre anuncio de productos, actualizaciones y ofertas especiales. Si se suscribe a las listas de correo electrónico de Nexgen, Nexgen puede enviarle periódicamente la información más reciente sobre productos y servicios. Puede optar por darse de baja en cualquier momento.<br>
        2. <b>¿Que informaciones recopila Nexgen ?</b> Su información personal ayuda a brindarle un mejor servicio de varias maneras. En esos momentos, Nexgen puede recopilar información personal relevante para la situación, como su nombre, número de teléfono, dirección de correo electrónico, información sobre los productos neo que posee, como la fecha de compra e información relacionada con algún problema de soporte o de servicio. Nexgen puede obtener de terceros información sobre usted cuando realiza transacciones, información sobre su uso de los métodos de pago y la identidad del emisor de su tarjeta o institución financiera.<br>
        3. <b>¿Nexgen divulga su información?</b> Nexgen se toma muy en serio su privacidad. Cuando utiliza un producto o servicio neo, confía su información a Nexgen. Nexgen no comparte información personal con empresas, organizaciones o personas ajenas a Nexgen (y compañías afiliadas) a menos que sea con su consentimiento o si así lo exige la ley.<br>
        4. <b>¿Cómo hace Nexgen para proteger su información personal?</b> Nexgen toma todas las precauciones razonables, incluidas las medidas administrativas, técnicas y físicas, para salvaguardar su información personal contra pérdida, robo, uso indebido, así como acceso, divulgación, alteración y destrucción no autorizados.<br>
        5. <b>Cumplimiento de la privacidad y los datos específicos de la región.</b> Los productos y servicios de Nexgen cumplen completamente, en el momento de la fecha de vigencia en vigor, con las siguientes regulaciones de privacidad de datos:<br>

        <div style="margin:0 0 0 20px;">
          a. GDPR, Reglamento general de protección de datos (UE)<br>
          b. FERPA, Derechos educativos de la familia y ley de privacidad (USA)<br>
          c. COPPA, Ley de protección de la privacidad en línea de los niños (USA)<br>
          d. DSL, Ley de seguridad de Datos (China)<br>
          e. PDPA, Ley de protección de datos personales (Singapur)<br>
          f. APPI, Ley de Protección de la Información (Japón)<br>
          g. PIPEDA, Ley de Protección de Datos Personales y Documentos Electrónicos (Canadá)<br>
          h. CCPA, Ley de privacidad del consumidor de California (USA)<br>
          i. CalOPPA Ley de protección de Privacidad en línea de California (USA)<br>
        </div>
        6. <b>Eliminación de información de identificación personal</b> Nexgen retiene el mínimo de Información de identificación personal ("PII") para permitir el acceso controlado a nuestros servicios y habilitar la validación de los certificados ganados. Sin embargo, si desea darse de baja y eliminar su PII de nuestras bases de datos, puede hacer su solicitud a legal@nexgenenglishonline.co pidiendo la eliminación de su PII diciendo: "He leído, entiendo y estoy de acuerdo con las Cláusula de eliminación de información de identificación personal ". La eliminación de su PII ocurrirá lo antes posible luego de la verificación de que la solicitud es legítima a no ser que Nexgen sea requerido para retener su PII para cumplir con las obligaciones legales aplicables o para resolver disputas. Cuando se haya satisfecho una solicitud de eliminación de información de identificación personal antes de completar su curso, no podrá continuar estudiando. Adicionalmente, Nexgen no podrá verificar ningún certificado neo ganado si su PII ha sido eliminada.<br>

        <br><b>Registro y requisitos</b> <br><br>
        1. Debe registrarse y pagar una cierta tarifa para obtener acceso completo a las aplicaciones y funcionalidades de Nexgen. se le pedirá que proporcione su información y datos personales (“Personal Information”) como parte del proceso.<br>
        2. Antes del pago, se le pedirá que compre un Certificado en su siguiente nivel, que define la tarifa que se le cobrará.<br>
        3. Una vez que haya finalizado el proceso de registro y pago, Nexgen le pedirá que cree un único inicio de sesión (“neo Universal Login") que se utilizará para acceder a las Aplicaciones.<br>
        4. Para comenzar a estudiar, debes descargar las aplicaciones neo Study y Myneo App en un dispositivo que cumpla con los requisitos según se explica aquí https://nexgenenglishonline.co/support<br>
        5. Al obtener una cuenta a través del proceso de registro y pago, también obtiene los tokens de coaching necesarios, según la selección de su paquete, y deberá programar en la aplicación Myneo. sesiones de coaching en línea con nuestros coaches capacitados<br>

        <div style="margin:0 0 0 20px;">
          5.1 Cinco sesiones de coaching están incluidos otorgado por Nexgen por cada nivel de certificación para quienes hayan pagado el acceso a Nexgen Live en y son necesarios para cumplir con los Requisitos de puntos de coaching. Aquellos con Nexgen Live que no se presenten a una sesión agendada , no ganara puntose coaching, por tanto Nexgen no será responsable por su incumplimiento. En este caso, debe comprar una sesión adicional a fin de cumplir con el requisito de completar los puntos de coaching.<br>
          5.2 Nexgen ofrece sesiones adicionales sesiones para comprar. Si compra más sesiones durante su estudio, éstas no serán utilizadas después de completar su nivel de certificación sino que permanecerán en su cuenta y se pueden utilizar a su discreción; sin embargo, las sesiones no utilizadas no son reembolsables.<br>
          5.3 Nexgen no limita la frecuencia de agendar sesiones de coaching en línea con coches de Nexgenen capacitadoes a través de la aplicación. Sin embargo, para un estudio eficaz, Nexgen sugiere reservar una sesión de coaching al menos una vez cada dos semanas como se indica arriba en los Términos de Servicio de Nexgen.<br>
          5.4 Los navegadores compatibles se pueden encontrar aquí https://nexgenenglishonline.co/support<br>
        </div>
      </p>
      <p class="eula">
        <br><b>Terminación</b><br><br>
        Este EULA permanecerá en vigor hasta que usted o Nexgen lo den por terminado. Nexgen puede, a su entera discreción, en cualquier momento y por cualquier motivo o sin él, suspender o rescindir este EULA con o sin previo aviso. Este EULA terminará inmediatamente, sin previo aviso de Nexgen, si usted no cumple con alguna disposición de este EULA. También puede rescindir este EULA eliminando la Aplicación y todas las copias de la misma de su dispositivo móvil o computadora. Tras la terminación de este EULA, deberá dejar de usar la Aplicación y eliminar todas las copias de la misma de su dispositivo móvil o computadora. La terminación de este EULA no limitará ninguno de los derechos o recursos de Nexgen por ley o en equidad en caso de que usted incumpla (durante la vigencia de este EULA) cualquiera de sus obligaciones en virtud del presente EULA.

        <br><br><b>Enmiendas a este Acuerdo</b><br><br>
        Nexgen se reserva el derecho, a su sola discreción, de modificar o reemplazar este EULA en cualquier momento. Si una revisión es importante, Nexgen proporcionará un aviso con al menos 30 días de anticipación, antes de llevar a efecto cualquier término. Lo que constituye un cambio material se determinará a la entera discreción de Nexgen. Si continúa accediendo o utilizando la Aplicación de Nexgen después de que las revisiones entren en vigencia, usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, ya no está autorizado a usar la Aplicación.

        <br><br><b>Sin garantía</b><br><br>
        Usted reconoce y acepta expresamente que el uso de la Aplicación es bajo su propio riesgo. En la medida máxima permitida por la ley aplicable, la Aplicación y cualquier servicio prestado o proporcionado por la Aplicación se proporcionan "tal cual" y "según disponibilidad", con todas las fallas y sin garantía de ningún tipo, y Nexgen renuncia a todas las garantías y condiciones. Sobre la aplicación y cualquier servicio, ya sea expreso, implícito o legal, incluidas, entre otras, las garantías implícitas y / o condiciones de comerciabilidad, calidad satisfactoria, idoneidad para un propósito particular, precisión, disfrute silencioso y no infracción de derechos de terceros. Ninguna información o consejo oral o escrito proporcionado por Nexgen o sus representantes autorizados creará una garantía. Si la Aplicación o el servicio resultan defectuosos, usted asume el costo total de todos los servicios, reparaciones o correcciones necesarios. Algunas jurisdicciones no permiten la exclusión de garantías implícitas o limitaciones sobre los derechos legales aplicables de un consumidor, por lo que es posible que la exclusión y las limitaciones anteriores no se apliquen en su caso.

        <br><br><b>Limitación de responsabilidad</b><br><br>
        En la medida en que no lo prohíba la ley, en ningún caso Nexgen será responsable por lesiones personales o daños incidentales, especiales, indirectos o consecuentes, incluidos, entre otros, daños por lucro cesante, pérdida de datos, interrupción del negocio o cualquier otro daño o pérdida comercial, que surja de o esté relacionado con su uso o incapacidad para usar la Aplicación, independientemente de la causa, independientemente de la teoría de la responsabilidad (contrato, agravio o de otro tipo) e incluso si Nexgen ha sido advertido de la posibilidad de tales daños. Algunas jurisdicciones no permiten la limitación de responsabilidad por lesiones personales, o daños incidentales o consecuentes, por lo que es posible que esta limitación no se aplique a usted. En ningún caso, la responsabilidad total de Nexgen ante usted por todos los daños (excepto los exigidos por la ley aplicable en casos que involucren lesiones personales) excederá el monto equivalente al precio de compra de la Aplicación. La limitación anterior se aplicará incluso si el recurso mencionado anteriormente no cumple con su propósito esencial.

        <br><br><b>Ley aplicable</b><br><br>
        Las leyes de California, Estados Unidos, excluyendo sus conflictos de normas legales, regirán este EULA y su uso de la Aplicación. Su uso de la Aplicación también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales.

        <br><br><b>Acuerdo completo</b><br><br>
        El EULA constituye el acuerdo completo entre usted y Nexgen con respecto al uso de la Aplicación y reemplaza todos los acuerdos escritos u orales anteriores y contemporáneos entre usted y Nexgen.

        <br><br><b>Información del contacto</b><br><br>
        Si tiene preguntas sobre este Acuerdo, comuníquese con nosotros a legal@nexgenenglishonline.co
      </p>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .eula {
    text-align: justify;

    b {
      font-weight: bold;
    }
  }
</style>
